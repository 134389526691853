import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {apiProductItemDetailById} from "../../../common/api/product/item/apiProductItemDetailById";
import {ItemInterface} from "../../../common/interfaces/ItemInterface";
import {LanguageInterface} from "../../../common/interfaces/LanguageInterface";
import {apiTranslationLanguages} from "../../../common/api/product/translation/apiTranslationLanguages";

export const loadProductDetail = createAsyncThunk(
    'productDetail/load',
    async ({ id, language }: { id: string, language: string }) => {
        const itemResponse = await apiProductItemDetailById(id, language)
        const languages = await apiTranslationLanguages(language)
        return {
            item: itemResponse.data?.items?.item,
            languages: languages.data?.items?.languages,
        }
    }
)


export interface ProductDetailInterface {
    loading: boolean,
    languages?: [LanguageInterface],
    item?: ItemInterface,
}

const initialState: ProductDetailInterface = {
    loading: false
}

export const productDetailPageSlice = createSlice({
    name: 'productDetail',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(loadProductDetail.pending, (state) => {
            state.loading = true
        })
        builder.addCase(loadProductDetail.fulfilled, (state, action: PayloadAction<{ item: ItemInterface , languages: [LanguageInterface]}>) => {
            state.item = action.payload.item
            state.languages = action.payload.languages
            state.loading = false
        })
    },
})

export const {} = productDetailPageSlice.actions

export const productDetailReducer = productDetailPageSlice.reducer
